var mymap = L.map('map').setView([51.493, -0.027], 2);

L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, <a href="https://openplaques.org/">OpenPlaques</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    maxZoom: 18,
    id: 'mapbox.light',
    accessToken: 'pk.eyJ1IjoiZ2VyZ28iLCJhIjoiYjg3SWtxbyJ9.lRj5RY9cyv8zHLUYhg_qvA'
}).addTo(mymap);

function loadJSON(callback) {   
    var xobj = new XMLHttpRequest();
        xobj.overrideMimeType("application/json");
    xobj.open('GET', 'open-plaques-no-zero.json', true);
    xobj.onreadystatechange = function () {
          if (xobj.readyState == 4 && xobj.status == "200") {
            // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
            callback(xobj.responseText);
          }
    };
    xobj.send(null);
}

function randomNFeature(obj, n, callback) {
    var prefix = `{
        "type": "FeatureCollection",
        "features": [`
    var suffix = `]
}`
    var randomNGeoJSON = {};
    randomNGeoJSON.type = "FeatureCollection";
    randomNGeoJSON.features = [];

    featureColl = JSON.parse(obj);
    var tempFeatures = featureColl.features;
        var chosenFeatures = [];
        for (i = n; i > 0; i--) {
            indexToChose = tempFeatures.length * Math.random() << 0
            chosenFeatures.push(tempFeatures[indexToChose]);
            randomNGeoJSON.features.push(tempFeatures[indexToChose]);
            tempFeatures.splice(indexToChose, indexToChose+1);
        }
    // console.log(chosenFeatures);
    // console.log(prefix + JSON.parse(chosenFeatures) + suffix);
    // callback(prefix + chosenFeatures + suffix);
    console.log(randomNGeoJSON);
    callback(randomNGeoJSON);
}

// var plaquesJSON;

function init() {
    loadJSON(function(response) {
    // Select N features at random while parsing JSON string into object
    randomNFeature(response, 5, function(randomN) {
        L.geoJSON(randomN, {
            onEachFeature: function (featureData, featureLayer) {
                popup = featureLayer.bindPopup(JSON.stringify(featureData.properties.inscription,null,' ').replace(/[\{\}"]/g,''));
                featureLayer.on('click', function() {
                    console.log("Clicked on Blue Plaque with text: \"" + featureData.properties.inscription + "\"");
                });
            }
        }).addTo(mymap);
        });
    });
}

init();